<template>
  <Page class="page-items page-show-item">
    <template #title>Dettagli offerta</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: editItemRouteName }">Modifica</router-link>
      <button v-if="item.enabled" type="button" @click.prevent="removeItem" class="btn btn-outline-danger ml-2">Cancella</button>
      <button v-else-if="!item.disabledByAdmin" type="button" @click.prevent="restoreItem" class="btn btn-outline-light ml-2">Ripristina</button>
    </template>

    <template #default>
      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr v-if="isAdmin">
            <th>Shop</th>
            <td><router-link :to="{ name: 'shops.show', params: { id: item.shop.id } }">{{ item.shop.name }}</router-link></td>
          </tr>
          <tr>
            <th>Nome</th>
            <td>
              <div class="d-flex">
                <div class="col p-0">
                  {{ item.name }}
                </div>
                <div class="col-auto pr-0">
                  <button type="button" @click.prevent="duplicateItem" class="btn btn-outline-secondary">Duplica offerta</button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>Prezzo</th>
            <td>
              <template v-if="item.priceVariable">Dinamico</template>
              <template v-else>{{ item.price | currency }}</template>
            </td>
          </tr>
          <tr>
            <th>Spedizione</th>
            <td v-if="item.askForShippingData === 'no'">Non permessa</td>
            <td v-else-if="item.askForShippingData === 'required'">Obbligatoria</td>
            <td v-else-if="item.askForShippingData === 'optional'">Facoltativa ({{ item.shippingCost | currency }})</td>
            <td v-else>-</td>
          </tr>
          <tr>
            <th>Identificativo</th>
            <td>
              <a v-if="item.enabled && !item.isPrivate" :href="item.url" target="_blank">{{ item.slug }}</a>
              <template v-else>{{ item.slug }}</template>
            </td>
          </tr>
          <tr>
            <th>Stato</th>
            <td v-if="!item.sellable">
              Offerta non in corso
              <template v-if="item.disabledByAdmin">(Rimossa da eziCommerce)</template>
              <template v-else-if="!item.enabled">(Rimossa)</template>
              <template v-else-if="item.sellableSince && !item.sellableUntil">
                (attiva dal {{ sellableSince }})
              </template>
              <template v-else-if="!item.sellableSince && item.sellableUntil">
                (attiva fino al {{ sellableUntil }})
              </template>
              <template v-else-if="item.sellableSince && item.sellableUntil">
                (attiva dal {{ sellableSince }} al {{ sellableUntil }})
              </template>
            </td>
            <td v-else>
              {{ item.enabled ? 'Offerta attiva' : 'Offerta non attiva' }}
            </td>
          </tr>
          <tr>
            <th>Visibile nella vetrina</th>
            <td>{{ item.isPrivate ? 'No' : 'Sì' }}</td>
          </tr>
          <tr>
            <th>Descrizione</th>
            <td>{{ item.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div>
        <button type="button" class="btn btn-outline-primary" @click.prevent="fetchReport('teamSystem')">
          Vendite mese passato (Formato Team System)
        </button>
        <button type="button" class="ml-4 btn btn-outline-primary" @click.prevent="fetchReport('csv')">
          Vendite mese passato (Formato CSV)
        </button>
      </div>

      <div class="mt-4">
        <button type="button" class="btn btn-outline-primary" @click.prevent="fetchReport('teamSystem', 'current')">
          Vendite parziali mese corrente (Formato Team System)
        </button>
        <button type="button" class="ml-4 btn btn-outline-primary" @click.prevent="fetchReport('csv', 'current')">
          Vendite parziali mese corrente (Formato CSV)
        </button>
      </div>

      <div v-if="item.url">
        <img :src="item.qr" :alt="'QR Code to ' + item.url">
      </div>
    </template>
  </Page>
</template>
<script>

import rolesMixin from '@/mixins/roles.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import currencyFilter from '@/filters/formatCurrency.js';
import { getDateFormatted, toDateTime, getYmd } from '@/helpers/date.js';
import saveStringAsFile from '@/helpers/saveStringAsFile.js';

import Page from '@/views/components/Private/Page.vue';

export default {
  mixins: [rolesMixin, confirmWithModalMixin],
  components: {
    Page,
  },
  filters: {
    currency: currencyFilter,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sellableSince () {
      if (!this.item.sellableSince) {
        return '';
      }

      return getDateFormatted(toDateTime(this.item.sellableSince));
    },
    sellableUntil () {
      if (!this.item.sellableUntil) {
        return '';
      }

      return getDateFormatted(toDateTime(this.item.sellableUntil));
    },
    editItemRouteName () {
      if (!this.isAdmin && this.item.isComing) {
        return 'items.coming.edit';
      }

      if (!this.isAdmin && this.item.isExpired) {
        return 'items.expired.edit';
      }

      return 'items.edit';
    },
  },
  methods: {
    removeItem () {
      if (!this.item.enabled) {
        return;
      }

      this.confirm('Cancellare l\'offerta?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.removeItem(this.item.id)
            .then(() => {
              this.$router.replace({ name: 'items.list' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    restoreItem () {
      if (this.item.enabled || this.item.disabledByAdmin) {
        return;
      }

      this.confirm('Ripristinare l\'offerta?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.restoreItem(this.item.id)
            .then(() => {
              this.$router.replace({ name: 'items.list' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    duplicateItem () {
      this.confirm('Duplicare l\'offerta? (L\'offerta duplicata sarà inserita tra le offerte scadute)', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.duplicateItem(this.item.id)
            .then((res) => {
              this.$router.replace({ name: 'items.expired' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    fetchReport (format = 'teamSystem', when = 'last') {
      const now = new Date();

      let previousMonthFirstDay;
      let previousMonthLastDay;
      if (when === 'current') {
        previousMonthFirstDay = new Date(now.getFullYear(), now.getMonth(), 1);

        previousMonthLastDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        previousMonthLastDay.setDate(previousMonthLastDay.getDate() - 1);
      } else {
        previousMonthFirstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);

        previousMonthLastDay = new Date(now.getFullYear(), now.getMonth(), 1);
        previousMonthLastDay.setDate(previousMonthLastDay.getDate() - 1);
      }

      this.$api.exportPurchases(format, {
        since: getYmd(previousMonthFirstDay),
        until: getYmd(previousMonthLastDay),
        item: this.item.id,
      })
        .then(res => res.data)
        .then(resBody => {
          saveStringAsFile(`ezicommerce-purchases-item-${this.item.id}.${format === 'teamSystem' ? 'teamSystem' : 'csv'}`, resBody);
        })
        .catch(err => this.$log.error(err))
      ;
    },
  },
};

</script>
