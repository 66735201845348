import confirmService from '../services/confirmService.js';

export default {
  data: () => ({
    justOpened: false,
    confirmPromise: null,
    confirmPromiseResolve: null,
  }),
  computed: {
    confirmModalClosed () {
      return confirmService.closed;
    },
    confirmModalAccepted () {
      return confirmService.accepted;
    },
  },
  watch: {
    confirmModalClosed () {
      if (this.justOpened) {
        this.justOpened = false;

        return;
      }

      if (!this.confirmPromiseResolve) {
        return;
      }

      this.confirmPromiseResolve(this.confirmModalAccepted);
      this.resetConfirm();
    },
  },
  methods: {
    confirm (question, okLabel = 'Ok', cancelLabel = 'Cancel') {
      this.confirmPromise = new Promise(resolve => {
        this.confirmPromiseResolve = resolve;
        this.justOpened = true;
        confirmService.open(question, okLabel, cancelLabel);
      });

      return this.confirmPromise;
    },
    resetConfirm () {
      confirmService.close();
      this.confirmPromise = null;
      this.confirmPromiseResolve = null;
    },
  },
  mounted () {
    confirmService.close();
  },
  destroyed () {
    confirmService.close();
  },
};
